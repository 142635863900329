.confirmation-page {
  min-width: 700px;
}

.confirmation-content {
  text-align: left;
  padding: 16px 93px;
  background-color: #FFFFFF;
}

.confirmation-space {
  margin: 16px auto;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: #E3E3E3;
}

.proposal-terms-space {
  margin: 16px 0px;
  max-width: 700px;
  padding: 16px;
}

.proposal-terms-space h1,
.proposal-terms-space h2,
.proposal-terms-space h3 {
    font-weight: bold;
}