.success-page {
  min-width: 700px;
}

.success-content {
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.success-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.success-text-space {
  margin: 16px auto;
  max-width: 500px;
  display: block;
}

.success-text-title {
  font-size: 30px;
  color: #1A1919;
}

.success-text-content {
  font-size: 18px;
}

.success-text-space .ant-space-item{
  margin-bottom: 20px;
}

.download-terms {
  padding: 0px;
}