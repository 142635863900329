.custom-steps {
  max-width: 600px;
  padding-bottom: 40px;
}

/* Cor da linha entre steps */
.custom-steps .ant-steps-item-title::after {
  background-color: #cececd !important;
}

/* Step concluido */
/* Cor do título */
.custom-steps .ant-steps-item-finish .ant-steps-item-title {
  color: #1A1919 !important;
}
/* Cor do background e da borda do círculo */
.custom-steps .ant-steps-item-finish .ant-steps-item-icon{
  background-color: #FF372D !important;
  border-color: #FF372D !important;
}
/* Cor do checkmark */
.custom-steps .ant-steps-item-finish .ant-steps-icon {
  color: #FFFFFF !important;
}

/* Step atual */
/* Cor do título */
.custom-steps .ant-steps-item-active .ant-steps-item-title {
  color: #FF372D !important;
}
/* Cor do background e da borda do círculo */
.custom-steps .ant-steps-item-active .ant-steps-item-icon{
  background-color: #FFFFFF !important;
  border-color: #FF372D !important;
  ;
}
/* Cor do número */
.custom-steps .ant-steps-item-active .ant-steps-icon {
  color: #FF372D !important;
}

/* Step futuro */
/* Cor do título */
.custom-steps .ant-steps-item-wait .ant-steps-item-title {
  color: #2F2D2D !important;
}
/* Cor do background e da borda do círculo */
.custom-steps .ant-steps-item-wait .ant-steps-item-icon{
  background-color: #FFFFFF !important;
  border-color: #2F2D2D !important;
  ;
}
/* Cor do número */
.custom-steps .ant-steps-item-wait .ant-steps-icon {
  color: #2F2D2D !important;
}