.header {
  min-width: 700px;
}

.custom-header {
  background: #FFFFFF;
  padding: 0px 98px;
  border-width: 1px;
  border-bottom-style: solid;
  border-color: #E9ECF0;
}