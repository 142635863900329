.footer {
  min-width: 700px;
}

/* upper footer */
.upper-footer {
  background-color: #525254;
  padding: 64px 93px 64px 93px;
  display: flex;
}

.upper-footer .footer-logo {
  width: 79px;
  height: 32px;
  color: #FFFFFF;
}

.upper-footer .vertical-divider {
  width: 1px;
  height: 24px;
  background-color: #6C7275;
  margin-right: 32px;
  margin-left: 58px;
}

.upper-footer .slogan-text {
  color: #E8ECEF;
}

/* lower footer */
.lower-footer {
  background-color: #434141;
  padding: 32px 93px 32px 93px;
}

.lower-footer .white-text {
  color: #FFFFFF;
}

.lower-footer .link-text {
  color: #FE332E;
}

.lower-footer .link-text:hover {
  color: #FFFFFF;
}

