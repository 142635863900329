/* PROPOSAL FORMS */
.proposal {
	padding: 32px 40px 32px 93px;
	background-color: #FFFFFF;
}

.forms-title {
	font-size: 30px;
	color: #1A1919;
}

.forms {
	padding-top: 40px;
}

.client-info-title,
.company-info-title,
.charger-info-title {
  color: #24028A;
  font-size: 24px;
}

.form-field {
	padding-bottom: 5px;
}

.form-field .ant-form-item-label {
	padding-bottom: 0px !important;
}
.form-field .ant-form-item-label > label {
	font-size: 15px;
	color: #222730;
}

.form-field .ant-form-item-explain-error {
	font-size: 14px;
	color: #FF372D;
}

.form-field .ant-input,
.form-field .ant-select-selection-search,
.form-field .ant-input-number-input {
	min-height: 50px;
}

.button {
	padding: 28px 64px;
	background-color: #5B00EF;
}

/* PLAN DETAILS */
.plan-details {
	padding: 32px 48px 32px 48px;
	color: #1A1919;
	background-color: #EFE5FF !important;
}

.plan-details h2 {
	color: #5B00EF;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 16px;
}

.plan-details .ant-collapse-content-box {
	font-size: 14px;
	line-height: 16px;
	color: #434141;
}

.plan-details .ant-collapse {
	background-color: rgba(0, 0, 0, 0);
}

.plan-details h1 {
	color: #24028A;
	font-size: 24px;
	font-weight: 400;
}

.plan-details h1.plan-name {
	font-weight: 500;
}

.plan-details .ant-divider {
	margin-bottom: 16px;
}

.plan-details .ant-divider.before-faq {
	margin-bottom: 0px;
}

.plan-details .plan-price {
	font-size: 30px;
	font-weight: 500;
}

.plan-details ul {
	list-style: none;
	margin-top: 8px;
	line-height: 24px;
	padding-left: 20px; /* Adjust for space before bullet */
	position: relative;
}

.plan-details ul li {
	position: relative;
	padding-left: 24px; /* Space for the SVG bullet */
	margin-left: -20px;
}

.plan-details ul li::before {
	content: '';
	display: inline-block;
	width: 18px;
	height: 18px;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none"><path d="M6.75012 12.1274L3.62262 8.99988L2.55762 10.0574L6.75012 14.2499L15.7501 5.24988L14.6926 4.19238L6.75012 12.1274Z" fill="%232F2D2D"/></svg>');
	background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	top: 3px; /* Adjust to vertically align the bullet */
}

.plan-details .plan-tupi-rate-warning {
	background-color: #FFF0B7;
	padding: 16px 24px 16px 24px;
	margin-bottom: 36px;
}

.plan-details .no-tupi-rate-space {
	margin-bottom: 36px;
}


